@import 'assets/mixins';

.title {
  padding-left: 20px;
  @include ellipsis;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.graph {
  width: 100%;
  height: 100%;
}

.actionsBtnWrapper {
  width: 90%;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.dltButton {
  width: 90%;
}
.inactive {
  pointer-events: none; // pointer-events not supported below IE11
  position: fixed;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;
  border-radius: 10px;
  padding: 10px 40px 40px 40px;
  height: 90%;
}

.tabsContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.leftSection {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.verticalLine {
  border-left: 2px solid #d8d8d8;
  height: 35%;
}

.rightSection {
  flex: 2;
  height: 90%;
}

.tabTitle {
  width: 90%;
  padding: 20px;
  border-radius: 5px;
  border-style: solid;
  border-width: 0.5px;
  border-color: #d8d8d8;
  margin-bottom: 20px !important;
  text-transform: capitalize;
}
.tabTitle:hover {
  cursor: pointer;
}

.table {
  width: 100%;
  border-collapse: collapse;

  & th, & td{
    padding: 8px;
    border: 1px solid #ddd;
  }
}
.table th {
  background-color: #f2f2f2;
}

.table td:nth-child(1) {
  width: 10%;
}

.table td:nth-child(2) {
  width: 85%;
}

.table td:nth-child(3) {
  width: 5%;
  text-align: center;
}

.tableContainer {
  height: 90%;
  overflow-y: auto;
}